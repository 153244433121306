import type { IStarInstance } from "~/components/canvas/newStars/types";

export default class StarStructure {
  stars: IStarInstance[];
  animatedStars: IStarInstance[];
  gridCellSize: number;
  grid: Record<string, IStarInstance[]>;
  starDensity = 0.0015;

  constructor () {
    this.stars = [];
    this.animatedStars = [];
    this.gridCellSize = 40;
    this.grid = {};
  }

  hashPosition ( x: number, y: number ) {
    return `${Math.floor( x / this.gridCellSize )},${Math.floor( y / this.gridCellSize )}`;
  }

  tryAddStar ( star: IStarInstance ) {
    const key = this.hashPosition( star.x, star.y );

    const radius = star.radius * 2;
    const neighbors = [
      key,
      this.hashPosition( star.x + radius, star.y ),
      this.hashPosition( star.x - radius, star.y ),
      this.hashPosition( star.x, star.y + radius ),
      this.hashPosition( star.x, star.y - radius ),
    ];

    for ( const neighbor of neighbors ) {
      if ( this.grid[neighbor] ) {
        for ( const existing of this.grid[neighbor] ) {
          const dx = existing.x - star.x;
          const dy = existing.y - star.y;
          const distance = Math.sqrt( dx * dx + dy * dy );

          if ( distance < existing.radius + radius ) {
            return false; // Collision detected
          }
        }
      }
    }

    this.stars.push( star );
    if ( star.radius > 2 || Math.random() > 0.6 ) {
      this.animatedStars.push( star );
    }

    this.grid[key] = this.grid[key] || [];
    this.grid[key].push( star );

    return true;
  }
}

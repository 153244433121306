import { Sprite } from "pixi.js";

export type StarArgs = {
  textures: Record<string, any>;
  twinkleSpeed?: number;
  radius?: number;
  x: number;
  y: number;
};

export type BaseStarArgs = {
  texture: any;
  twinkleSpeed?: number;
  radius?: number;
  x: number;
  y: number;
};

export default abstract class Star extends Sprite {
  twinkleSpeed: number;
  radius: number;
  color: string | number;
  z: number;
  initialX: number;
  initialY: number;

  protected constructor ( args: BaseStarArgs ) {
    super( args.texture );
    this.twinkleSpeed = args?.twinkleSpeed ?? 0.1 + Math.random() * 3.9;
    this.radius = args?.radius ?? this.generateWeightedRadius();
    this.color = this.getRandomColor();
    this.x = args.x;
    this.y = args.y;
    this.z = 0.4 + Math.random() * 2;
    this.initialX = this.x;
    this.initialY = this.y;
  }

  draw () {
    this.rotation = Math.random() * Math.PI * 2;
    this.alpha = 0.65 + 0.35 * Math.sin( Date.now() * 0.001 * this.twinkleSpeed + this.initialX + this.initialY );
    this.width = this.radius;
    this.height = this.radius;
    this.tint = this.color;
  }

  generateWeightedRadius () {
    const radiusChance = Math.random();
    if ( radiusChance <= 0.7 ) {
      return 1 + Math.random();
    }
    else {
      return 1.5 + Math.random() * 3;
    }
  }

  getRandomColor () {
    const randomIndex = Math.floor( Math.random() * this.colors.length );
    return this.colors[randomIndex];
  }

  colors = [
    0xffffff, // White
    0xfffffe, // White
    0xfffffd, // White
    0xfff4e9, // Light peach
    0xffe8ea, // Light pink
    0xf8d5f8, // Light violet
    0xffe8d0, // Mid peach
    0xffd3d8, // Mid pink
    0xf1aaf1, // Mid violet
    0xffd48f, // Dark peach
    0xff99a5, // Dark pink
    0xd4adf0, // Dark violet
  ];
}

<script setup lang="ts">
import { Application } from "pixi.js";
import StarStructure from "~/components/canvas/newStars/starStructure";
import { ShootingStarSystem } from "~/components/canvas/newStars/systems/shootingStarSystem";
import { StationaryStarSystem } from "~/components/canvas/newStars/systems/stationaryStarSystem";
import type { IStarSystem } from "~/components/canvas/newStars/types";

const wrapper = ref<HTMLDivElement>();

const canvasStore = useCanvasStore();
const overlayStore = useOverlayStore();

const props = withDefaults( defineProps<{
  noScroll?: boolean;
}>(), {
  noScroll: false,
} );

let app: Application | null = null;
let structure: StarStructure | null = null;
let systems: IStarSystem[] | null = null;

let previousWidth = 0;
let previousHeight = 0;
let previousScrollX = 0;
let previousScrollY = 0;

const reducedMotionComposable = usePreferredReducedMotion();
const reducedMotion = computed( () => reducedMotionComposable.value === "reduce" );

function onResize () {
  if ( !app ) {
    throw new Error( "Unable to get app" );
  }

  if ( !systems ) {
    throw new Error( "Unable to get systems" );
  }

  const newWidth = app.canvas.clientWidth;
  const newHeight = app.canvas.clientHeight;

  const args = {
    previousWidth,
    previousHeight,
    newWidth,
    newHeight,
  };

  systems.forEach( ( x ) => {
    x.onResize?.( args );
  } );

  // Update previous dimensions
  previousWidth = newWidth;
  previousHeight = newHeight;
}

function onScroll () {
  if ( !systems ) {
    throw new Error( "Unable to get systems" );
  }

  const previousX = previousScrollX;
  const previousY = previousScrollY;
  const newX = window.scrollX;
  const newY = window.scrollY;
  const deltaX = newX - previousX;
  const deltaY = newY - previousY;

  const args = {
    previousX,
    previousY,
    newX,
    newY,
    deltaX,
    deltaY,
  };

  previousScrollX = newX;
  previousScrollY = newY;

  systems.forEach( ( x ) => {
    x.onScroll?.( args );
  } );
}

function onScrollEnd () {
  if ( !systems ) {
    throw new Error( "Unable to get systems" );
  }

  systems.forEach( ( x ) => {
    x.onScrollEnd?.();
  } );
}

let badFpsCount = 0;
function checkFps () {
  if ( ( app?.ticker.FPS ?? 999 ) < 14.8 ) {
    badFpsCount += 1;
    console.log( "saw a bad FPS", app?.ticker.FPS );
  }
  else {
    badFpsCount = 0;
  }

  if ( badFpsCount === 10 ) {
    app?.ticker.stop();
    console.warn( "slow device detected, stopping animations", app?.ticker.FPS );

    if ( checkFpsIntervalId ) {
      window.clearInterval( checkFpsIntervalId );
    }
  }
}

let checkFpsIntervalId: number | null = null;

async function createPixi () {
  // Create a new application
  app = canvasStore.starCanvas.pixi;
  structure = canvasStore.starCanvas.structure;
  systems = canvasStore.starCanvas.systems;

  if ( !app ) {
    app = new Application();
    structure = new StarStructure();
    systems = [];

    // Initialize the application
    await app.init( {
      resizeTo: window,
      resolution: window.devicePixelRatio,
      antialias: true,
      autoDensity: true,
      backgroundAlpha: 0,
      clearBeforeRender: true,
    } );

    const args = {
      app,
      structure,
    };

    systems.push( new StationaryStarSystem( args ) );
    systems.push( new ShootingStarSystem( args ) );

    await Promise.all( systems.map( x => x.onLoad?.() ) );

    if ( !reducedMotion.value ) {
      app.ticker.add( ( ticker ) => {
        systems?.forEach( x => x.onUpdate?.( ticker ) );
      } );
    }

    canvasStore.starCanvas.pixi = app;
    canvasStore.starCanvas.structure = structure;
    canvasStore.starCanvas.systems = systems;
  }
  else {
    app?.ticker.start();
    app?.start();
    app.resize();
  }

  wrapper.value.appendChild( app.canvas );

  previousWidth = app.canvas.clientWidth;
  previousHeight = app.canvas.clientHeight;

  window.addEventListener( "resize", onResize );
  if ( !reducedMotion.value && !props.noScroll ) {
    document.addEventListener( "scroll", onScroll, { passive: true } );
    document.addEventListener( "scrollend", onScrollEnd, { passive: true } );
  }

  checkFpsIntervalId = window.setInterval( checkFps, 100 );
}

onMounted( async () => {
  await createPixi();
} );

watch( () => overlayStore.overlayOpen, ( val ) => {
  if ( val && app ) {
    app.ticker.stop();
    app.stop();
  }
  else if ( app ) {
    app.ticker.start();
    app.start();
  }
} );

onBeforeUnmount( () => {
  if ( !app ) {
    throw new Error( "Unable to get app" );
  }

  window.removeEventListener( "resize", onResize );
  document.removeEventListener( "scroll", onScroll );
  document.removeEventListener( "scrollend", onScrollEnd );

  app.ticker.stop();
  app.stop();
  wrapper.value?.removeChild( app.canvas );

  if ( checkFpsIntervalId ) {
    window.clearInterval( checkFpsIntervalId );
  }
} );
</script>

<template>
  <div ref="wrapper" />
</template>

<style scoped>

</style>

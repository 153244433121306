import type { Application, Ticker } from "pixi.js";
import { Assets } from "pixi.js";
import type StarStructure from "~/components/canvas/newStars/starStructure";
import type { ConstructorArgs, IStarSystem, OnResizeArgs, OnScrollArgs } from "~/components/canvas/newStars/types";
import DotStar from "~/components/canvas/newStars/stars/dotStar";
import FourOrFivePointedStar from "~/components/canvas/newStars/stars/fourOrFivePointedStar";
import FourPointedStar from "~/components/canvas/newStars/stars/fourPointedStar";
import EightPointedStar from "~/components/canvas/newStars/stars/eightPointedStar";
import { useCanvasStore } from "~/stores/canvas";

export class StationaryStarSystem implements IStarSystem {
  structure: StarStructure;
  app: Application;
  scrolling = false;
  textures: Record<string, any> = {};
  maxWidth = 0;
  maxHeight = 0;
  twinkling: Ref<boolean>;

  constructor ( args: ConstructorArgs ) {
    this.structure = args.structure;
    this.app = args.app;

    const { twinkling } = storeToRefs( useCanvasStore() );
    this.twinkling = twinkling;
  }

  async onLoad () {
    // Add the assets to load
    const textureNames = [
      "chubby_star",
      "chubby_star_hollow",
      "dot",
      "dot_hollow",
      "eight_star",
      "eight_star_hollow",
      "five_star",
      "five_star_hollow",
      "four_star",
      "four_star_hollow",
    ];

    textureNames.forEach( ( name ) => {
      Assets.add( { alias: name, src: `/stars/${name}.png` } );
    } );

    const texturesPromise = Assets.load( textureNames );
    this.textures = await texturesPromise;

    this.populateStarsInRegion( 0, 0, this.app.renderer.width, this.app.renderer.height );
  }

  onUpdate ( ticker: Ticker ) {
    if ( this.scrolling || !this.twinkling.value ) {
      return;
    }

    const time = ticker.lastTime * 0.001; // Convert to seconds for smoother animation
    const interpolationFactor = 0.05;

    for ( let i = 0; i < this.structure.animatedStars.length; i += 1 ) {
      const star = this.structure.animatedStars[i];
      const targetAlpha = 0.65 + 0.35 * Math.sin( time * star.twinkleSpeed + star.initialX + star.initialY );
      star.alpha += ( targetAlpha - star.alpha ) * interpolationFactor;
    }
  }

  onResize ( args: OnResizeArgs ) {
    if ( args.newWidth > this.maxWidth && args.newHeight > this.maxHeight ) {
      // Add stars to the newly exposed bottom-right corner
      this.populateStarsInRegion( args.previousWidth, args.previousHeight, args.newWidth, args.newHeight );
    }

    // Determine the regions that need new stars
    if ( args.newWidth > this.maxWidth ) {
      // Add stars to the newly exposed right area
      this.populateStarsInRegion( args.previousWidth, 0, args.newWidth, args.previousHeight );
    }

    if ( args.newHeight > this.maxHeight ) {
      // Add stars to the newly exposed bottom area
      this.populateStarsInRegion( 0, args.previousHeight, args.newWidth, args.newHeight );
    }

    if ( args.newHeight > this.maxHeight ) {
      this.maxHeight = args.newHeight;
    }

    if ( args.newWidth > this.maxWidth ) {
      this.maxWidth = args.newWidth;
    }
  }

  onScroll ( args: OnScrollArgs ) {
    this.scrolling = true;
    const delta = this.app.ticker.deltaTime;

    for ( let i = 0; i < this.structure.animatedStars.length; i += 1 ) {
      const star = this.structure.animatedStars[i];
      star.x -= delta * ( ( args.deltaX / 10 ) * ( star.radius / 4 ) );
      star.y -= delta * ( ( args.deltaY / 10 ) * ( star.radius / 4 ) );
    }
  }

  onScrollEnd () {
    this.scrolling = false;
  }

  calculateNumberOfStars ( width: number, height: number ) {
    const area = width * height;
    return Math.round( area * this.structure.starDensity );
  }

  populateStarsInRegion ( xMin: number, yMin: number, xMax: number, yMax: number ) {
    const targetCount = this.calculateNumberOfStars( xMax - xMin, yMax - yMin );
    const newTotal = targetCount + this.structure.stars.length;

    let attemptsSinceSuccess = 0;
    while ( this.structure.stars.length < newTotal ) {
      const x = Math.random() * ( xMax - xMin ) + xMin;
      const y = Math.random() * ( yMax - yMin ) + yMin;

      const newStar = this.createStar( x, y );
      const result = this.structure.tryAddStar( newStar );
      if ( result ) {
        newStar.draw();
        this.app.stage.addChild( newStar );
        attemptsSinceSuccess = 0;
      }
      else {
        attemptsSinceSuccess += 1;
      }

      if ( attemptsSinceSuccess > 20 ) {
        console.warn( "had to break, couldn't fit any more stars..." );
        break;
      }
    }
  }

  createStar ( x: number, y: number ) {
    const typeChance = Math.random();

    if ( typeChance <= 0.8 ) {
      return new DotStar( { textures: this.textures, x, y } );
    }
    else if ( typeChance <= 0.9 ) {
      return new FourOrFivePointedStar( { textures: this.textures, x, y } );
    }
    else if ( typeChance <= 0.95 ) {
      return new FourPointedStar( { textures: this.textures, x, y } );
    }
    else {
      return new EightPointedStar( { textures: this.textures, x, y } );
    }
  }
}

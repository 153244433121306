import Star, { type StarArgs } from "~/components/canvas/newStars/stars/star";
import type { IStarInstance } from "~/components/canvas/newStars/types";

export default class DotStar extends Star implements IStarInstance {
  hollow: boolean;

  constructor ( args: StarArgs ) {
    const hollow = Math.random() > 0.8;
    const texture = args.textures[hollow ? 'dot_hollow' : 'dot'];
    super( {texture, ...args} );
    this.hollow = hollow;
  }
}

import Star, { type StarArgs } from "~/components/canvas/newStars/stars/star";
import type { IStarInstance } from "~/components/canvas/newStars/types";

export default class EightPointedStar extends Star implements IStarInstance {
  lineLength: number;
  innerRadius: number;
  hollow: boolean;
  
  constructor ( args: StarArgs ) {
    const hollow = Math.random() > 0.8;
    const texture = args.textures[hollow ? 'eight_star_hollow' : 'eight_star'];
    super( {texture, ...args} );
    this.hollow = hollow;
    this.radius = 12 + Math.random() * 5;
    this.lineLength = this.radius;
    this.innerRadius = this.radius / 1.5;
  }
}

import Star, { type BaseStarArgs } from "~/components/canvas/newStars/stars/star";
import type { IStarInstance } from "~/components/canvas/newStars/types";

export default class ShootingStar extends Star implements IStarInstance {
  vx: number;
  vy: number;

  constructor ( args: BaseStarArgs ) {
    super( args );
    this.vx = 0;
    this.vy = 0;
  }

  resetScale() {
    this.width = this.radius;
    this.height = this.radius;
  }
}

import type { Ticker, Application } from "pixi.js";
import { Assets, MeshRope, Point } from "pixi.js";
import ShootingStar from "~/components/canvas/newStars/stars/shootingStar";
import type { ConstructorArgs, IStarSystem } from "~/components/canvas/newStars/types";
import { useCanvasStore } from "~/stores/canvas";

export class ShootingStarSystem implements IStarSystem {
  app: Application;
  shootingStar?: ShootingStar;
  trailTexture: any;
  starTexture: any;
  trail?: MeshRope;
  shooting: Ref<boolean>;

  points: Point[] = [];

  historyX: number[] = [];
  historyY: number[] = [];
  // historySize determines how long the trail will be.
  historySize = 15;
  ropeSize = 100;

  active = false;
  startTime = 0;
  speed = 1.8;
  duration = 500;
  fadeTime = 400;
  chance = 0.005;

  constructor ( args: ConstructorArgs ) {
    this.app = args.app;

    const { shooting } = storeToRefs( useCanvasStore() );
    this.shooting = shooting;

    for ( let i = 0; i < this.historySize; i++ ) {
      this.historyX.push( 0 );

      this.historyY.push( 0 );
    }

    for ( let i = 0; i < this.ropeSize; i++ ) {
      this.points.push( new Point( 0, 0 ) );
    }
  }

  async onLoad () {
    this.trailTexture = await Assets.load( "/stars/trail.png" );
    this.starTexture = await Assets.load( "/stars/dot.png" );
  }

  onUpdate ( args: Ticker ) {
    if ( !this.shooting.value ) {
      return;
    }

    if ( this.active && this.shootingStar && this.trail ) {
      this.shootingStar.x += this.shootingStar.vx * args.deltaTime * this.speed;
      this.shootingStar.y += this.shootingStar.vy * args.deltaTime * this.speed;

      // Update the mouse values to history
      this.historyX.pop();
      this.historyX.unshift( this.shootingStar.x );
      this.historyY.pop();
      this.historyY.unshift( this.shootingStar.y );
      // Update the points to correspond with history.
      for ( let i = 0; i < this.ropeSize; i++ ) {
        const p = this.points[i];

        // Smooth the curve with cubic interpolation to prevent sharp edges.
        const ix = this.cubicInterpolation( this.historyX, ( i / this.ropeSize ) * this.historySize );
        const iy = this.cubicInterpolation( this.historyY, ( i / this.ropeSize ) * this.historySize );

        p.x = ix;
        p.y = iy;
      }

      const elapsedTime = Date.now() - this.startTime;
      if ( elapsedTime > this.duration || this.shootingStar.x < -100 || this.shootingStar.y < -100 ) {
        const fadeElapsed = elapsedTime - this.duration;
        const fadeProgress = fadeElapsed / this.fadeTime;
        this.shootingStar.scale.set( 1 - fadeProgress );
        this.shootingStar.alpha = 1 - fadeProgress;
        this.trail.alpha = 1 - fadeProgress;

        if ( fadeProgress >= 1 ) {
          this.app.stage.removeChild( this.shootingStar );
          this.shootingStar.destroy();
          this.active = false;
        }
      }
      else {
        this.trail.alpha = 1;
      }
    }
    else if ( Math.random() < this.chance ) {
      this.start();
    }
  }

  start () {
    this.shootingStar = new ShootingStar( { texture: undefined, radius: 2, x: -100, y: -100 } );
    this.shootingStar.draw();
    this.app.stage.addChild( this.shootingStar );

    if ( !this.trail ) {
      this.trail = new MeshRope( { texture: this.trailTexture, points: this.points } );
      this.app.stage.addChild( this.trail );
    }

    this.trail.tint = this.shootingStar?.getRandomColor() ?? "#fff";

    this.active = true;

    this.shootingStar.x = Math.random() * ( this.app.renderer.width );
    this.shootingStar.y = Math.random() * ( this.app.renderer.height / 3 ); // start from the upper half of the screen

    for ( let i = 0; i < this.historySize; i++ ) {
      this.historyX[i] = this.shootingStar.x;

      this.historyY[i] = this.shootingStar.y;
    }

    // Define the horizontal and vertical velocity ranges
    const minHorizontalSpeed = 1;
    const maxHorizontalSpeed = 8;
    const minVerticalSpeed = 2;
    const maxVerticalSpeed = 5;

    let direction: number;

    // Determine the direction based on the x position
    if ( this.shootingStar.x < ( this.app.renderer.width * 0.25 ) ) {
      direction = 1; // Move right
    }
    else if ( this.shootingStar.x > ( this.app.renderer.width * 0.75 ) ) {
      direction = -1; // Move left
    }
    else {
      direction = Math.random() < 0.5 ? -1 : 1; // Randomly choose direction
    }

    // Generate random horizontal and vertical speeds
    this.shootingStar.vx = direction * ( Math.random() * ( maxHorizontalSpeed - minHorizontalSpeed ) + minHorizontalSpeed );
    this.shootingStar.vy = Math.random() * ( maxVerticalSpeed - minVerticalSpeed ) + minVerticalSpeed;

    this.shootingStar.resetScale();
    this.shootingStar.alpha = 1;
    this.startTime = Date.now();
  }

  clipInput ( k: number, arr: number[] ) {
    if ( k < 0 ) k = 0;
    if ( k > arr.length - 1 ) k = arr.length - 1;

    return arr[k];
  }

  getTangent ( k: number, factor: number, array: number[] ) {
    return ( factor * ( this.clipInput( k + 1, array ) - this.clipInput( k - 1, array ) ) ) / 2;
  }

  cubicInterpolation ( array: number[], t: number, tangentFactor = 1 ) {
    const k = Math.floor( t );
    const m = [this.getTangent( k, tangentFactor, array ), this.getTangent( k + 1, tangentFactor, array )];
    const p = [this.clipInput( k, array ), this.clipInput( k + 1, array )];

    t -= k;
    const t2 = t * t;
    const t3 = t * t2;

    return ( 2 * t3 - 3 * t2 + 1 ) * p[0] + ( t3 - 2 * t2 + t ) * m[0] + ( -2 * t3 + 3 * t2 ) * p[1] + ( t3 - t2 ) * m[1];
  }
}

import Star, { type StarArgs } from "~/components/canvas/newStars/stars/star";
import type { IStarInstance } from "~/components/canvas/newStars/types";

export default class FourPointedStar extends Star implements IStarInstance {
  lineLength: number;
  innerRadius: number;
  hollow: boolean;

  constructor ( args: StarArgs ) {
    const hollow = Math.random() > 0.7;
    const texture = args.textures[hollow ? 'four_star_hollow' : 'four_star'];
    super( {texture, ...args} );
    this.hollow = hollow;
    this.radius = 5 + Math.random() * 3;
    this.lineLength = this.radius;
    this.innerRadius = this.radius / 1.2;
  }
}

import Star, { type StarArgs } from "~/components/canvas/newStars/stars/star";
import type { IStarInstance } from "~/components/canvas/newStars/types";

export default class FourOrFivePointedStar extends Star implements IStarInstance {
  lineLength: number;
  points: 4 | 5;
  hollow: boolean;
  chubby: boolean;

  constructor ( args: StarArgs ) {
    const hollow = Math.random() > 0.7;
    const chubby = Math.random() > 0.5;
    const texture_prefix = chubby ? "chubby_star" : "five_star";
    const texture = args.textures[hollow ? texture_prefix +'_hollow' : texture_prefix ];
    super( {texture, ...args} );
    this.hollow = hollow;
    this.chubby = chubby;
    this.radius = 5 + Math.random() * 5;
    this.lineLength = this.radius * 1.5;
    this.points = Math.random() > 0.5 ? 5 : 4;
  }
}
